<template>
  <div class="container py-5 px-0 animate__animated animate__fadeIn">
    <h3 class="mb-4 text-center">Selecciona una opción</h3>
    <div class="list-group">
      <button v-for="(option, key) in options" :key="key" v-on:click="nextStep(option.name)" class="btn btn-white list-group-item list-group-item-action row">
        <i :class="option.icon" class="col-1"></i>
        <span class="col-11">{{ option.title }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginOptions',
  data() {
    return {
      options: ''
    }
  },
  methods: {
    nextStep(option) {
      switch (option) {
        case "password":
          this.$store.commit("setCurrentStep", "cloginpassword")
          break;
        case "device":
          this.$store.commit("setCurrentStep", "clogindevice")
          break;
        case "fingerprint":
          this.$store.commit("setCurrentStep", "cloginfingerprint")
          break;
      }
    },
  },
  mounted() {
    this.options = this.$store.getters.getLoginOptions
  },
}
</script>